.landing-page {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;

    .equipOverviewContainer{
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-height: inherit;
    }
    .equipOverviewContainer .eq-section-scrollable {
        height: inherit !important;
        flex: 1 1 auto;
    }
    .overview-collapsed + .st-view-wrapper{
        width: calc(100vw - 13px);
        height: calc(100vh - 80px);
    }
    .st-view-wrapper{
        width:calc(100% - 500px);
        display: flex;
        flex-direction: column;
        height: 100%;
        .sdds-inline-tabs-fullbleed--tab{
            cursor: pointer;
        }
        .sdds-navigation-tabs--tab__active{
            z-index: 1;
        }
    }
    .st-view-container {
        display: flex;
        flex-direction: column;
        margin: 0 var(--sdds-spacing-layout-8);
        flex: 1 1 auto;
    }

    .packageSelector {
        display: flex;
        align-self: center;
        margin: 5rem 0;
    }

    .packageSelector input,
    .packageSelector button {
        background-color: #F9FAFB !important;
        font-family: inherit;
    }

    .packageSelectorLabel {
        font-weight: bold;
        color: var(--sdds-grey-700);
        margin: 3rem 2rem 0 0;
    }

    .selectPackageMessage {
        
        color: var(--sdds-grey-700);
        margin-top: 30vh;
        align-self: center;
    }
    .section-scrollable {
        overflow-y: scroll;
        height: calc(100vh - 240px);
        flex: 1 1 auto;
        margin-bottom: 40px;
        .sdds-message-extended{
            max-width: unset;
        }
    }
}
