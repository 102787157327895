.function-container {
  display: flex;
  width: 100%;
  height: 176px;
  flex-direction: row;
  background-color: var(--sdds-grey-50);
  justify-content: space-between;
  padding-bottom: 0;
  margin-top: var(--sdds-spacing-layout-24);

  &:first-child {
    margin-top: 0;
  }

  .func-name {
    position: relative;

    .warning-svg-icon {
      position: absolute;
      width: 20px;
      height: 20px;
      cursor: pointer;
      top: -5px;

      &:hover {
        +.sdds-tooltip {
          display: block;
          position: absolute;
          opacity: 1;
        }
      }
    }

    .sdds-tooltip {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0px;
      color: var(--sdds-white);
      background-color: var(--sdds-grey-800);
      border-radius: 1rem;
      padding: 2rem;
      overflow-wrap: break-word;
      max-width: 48rem;
      z-index: 20000;
      opacity: 0;
      display: none;
      transition: opacity 200ms ease-in 0s, visibility 200ms ease-in 0s;
    }
  }

  .func-data {
    width: 80%;

    .sdds-detail-03,
    .sdds-headline-07 {
      margin-left: var(--sdds-spacing-layout-16);
    }

    .base-uri {
      word-break: break-all;
      word-wrap: break-word;
    }
  }

  .func-del {
    height: 16px;
  }

  .func-actions {
    display: flex;
    flex-direction: column;

    button {
      word-break: keep-all;
      word-wrap: none;
      white-space: nowrap
    }
  }
}

.func-assert-params {
  display: flex;
  width: 100%;
  background-color: var(--sdds-grey-100);
  padding-left: var(--sdds-spacing-layout-32);
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: var(--sdds-spacing-layout-16);

  .assert-param-lbl {
    text-transform: uppercase;
  }

  .assert-param {
    margin-left: auto;
    margin-top: var(--sdds-spacing-layout-16);

    input {
      height: 40px;

      &.error {
        border-color: var(--sdds-textfield-border-bottom-error) !important;
      }
    }
  }
}