.accordionWrapper {
    margin: 5rem 3rem;

    .header {
        font-family: 'Scania Office';
        padding: 3rem;
        background-color: var(--sdds-grey-300);
        font-weight: bold;
    }

    .bodywrapper {
        border-bottom: 1px solid var(--sdds-grey-300);
        font-family: 'Scania Office';
        &:hover{
            background-color: var(--sdds-grey-100);
        }
        .info {
            display: grid;
            grid-template-areas: 'left left right right right right';
            margin: 0 var(--sdds-spacing-layout-8);

            .left {
                display: flex;
                grid-area: left;

                .tag {
                    background-color: var(--sdds-positive);
                    color: var(--sdds-white);
                    border: none;
                    padding: 2px 8px;
                    text-align: center;
                    display: inline-block;
                    margin: 12px 6px;
                    border-radius: 16px;
                    font-size: 12px;
                }

                .tag_yellow {
                    background-color: var(--sdds-warning);
                }

                .unavailable {
                    color: var(--sdds-negative);
                    align-self: center;
                    margin: 0 var(--sdds-spacing-layout-8);
                }
            }

            .right {
                grid-area: right;
                display: flex;
                justify-self: right;

                .dropdown {
                    cursor: pointer;
                    align-self: center;
                    margin: var(--sdds-spacing-layout-8);
                }

                .dropdown_active {
                    svg {
                        transform: rotate(180deg);
                    }
                }

                .function_id {
                    background-color: var(--sdds-grey-200);
                    color: #56657A;
                    border: none;
                    padding: 4px 16px;
                    text-align: center;
                    display: inline-block;
                    line-height: 16px;
                    margin: 10px 8px;
                    font-size: 12px;
                    border-radius: 3px;
                }

                p {
                    min-width: 320px;
                }
            }
        }
    }

    .details {
        background-color: #F9FAFB;
        display: grid;
        grid-template-columns: auto auto auto;
        padding: 20px;

        .title {
            font-family: 'Scania Office';
            color: #56657A;
            font-size: 14px;
            font-weight: bold;
        }

        .delete {
            justify-self: right;
        }
    }
}

.error {
    color: var(--sdds-textfield-helper-error);
    margin-left: var(--sdds-spacing-element-8);
}

.form {
    background-color: #F9FAFB;
    font-family: 'Scania Office' !important;
    padding: var(--sdds-spacing-layout-16);
    margin: 0 var(--sdds-spacing-layout-16);

    .inputs_wrapper {
        display: grid;
        grid-template-columns: auto auto;
        gap: var(--sdds-spacing-layout-24);

        .input {
            input {
                background-color: var(--sdds-white) !important;
                width: 100%;
                border: 1px solid var(--sdds-grey-400);
                height: var(--sdds-spacing-layout-48);
                margin: 0 var(--sdds-spacing-element-8);
                padding: var(--sdds-spacing-element-8);
            }

            .tag {
                font-family: 'Scania Office';
                padding: var(--sdds-spacing-layout-8);
                margin: 0;
            }
        }

        .dropdown {
            width: 50%;
        }
    }

    .buttons {
        justify-content: flex-start;

        button {
            margin: var(--sdds-spacing-layout-32) var(--sdds-spacing-layout-8) 0 0;
        }
    }
}