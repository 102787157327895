.detailedViewModal {
  .sdds-inline-tabs-fullbleed--tab {
    user-select: none;

    &--back {
      display: none !important;
    }
  }

  .grey-50 {
    background-color: var(--sdds-grey-50);
  }

  .box {
    @extend .grey-50;
    height: auto;
    padding: var(--sdds-spacing-element-16);
    margin-bottom: var(--sdds-spacing-element-16);

    >h6 {
      margin-top: 0;
    }
  }

  .fault-code-box {
    padding: var(--sdds-spacing-element-16);

    .fault-code-txt {
      color: var(--sdds-negative);
      margin-bottom: var(--sdds-spacing-element-16);
    }

    .unknown-fault-code-box {
      box-sizing: border-box;
      width: 655px;
      height: 100px;
      display: flex;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid;
      border-color: var(--sdds-warning);
      color: var(--sdds-warning);
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .detail-box {
    padding: var(--sdds-spacing-element-8);
  }

  .logs-box {
    padding-top: var(--sdds-spacing-element-16);
    padding-right: var(--sdds-spacing-element-16);
    padding-bottom: var(--sdds-spacing-element-16);

  }

  .tab-panel {
    @extend .grey-50;
    display: none;
    height: auto;

    .tab-box {
      margin-bottom: var(--sdds-spacing-element-16);
    }

    >h6 {
      margin-top: 0;
    }

    &.active {
      display: block;
    }
  }
}

.detailedViewModal {
  color: #000000 !important;
}

.sdds-modal-headline {
  color: #000000 !important;
}