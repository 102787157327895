.pkg-right-panel {
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 214px);
  border: 1px solid var(--sdds-grey-200);
  background-color: var(--sdds-grey-50);

  .title-row {
    display: flex;
    justify-content: space-between;
  }


  .form-panel {
    padding-right: var(--sdds-spacing-layout-64);

    .pkg-name-section {
      margin-bottom: var(--sdds-spacing-layout-64);

      input {
        width: 100%;
        background-color: var(--sdds-white) !important;

        &.error {
          border-bottom: 2px solid var(--sdds-red) !important;
        }
      }
    }

    .pkg-type-section {
      margin-bottom: var(--sdds-spacing-layout-64);
    }

    input[type=radio] {
      background-color: unset !important;
      border-radius: unset !important;
      border: unset !important;
      border-bottom: unset !important;
      padding: unset !important;
    }

    .btn-section {
      display: flex;
      flex-direction: column;

      button {
        margin-top: var(--sdds-spacing-layout-24);
      }
    }
  }


.sdds-inline-tabs .sdds-inline-tabs-main {
  height: calc(100vh - 360px);
}

}