#add-function-modal {
    max-height: none;
    overflow-y: none;

    .info-spacer {
        position: sticky;
        padding-bottom: var(--sdds-spacing-layout-8);
        top: 0px;
        z-index: 10;
        display: flex;
        padding-right: 0;
        background-color: var(--sdds-white);
        .add-func-modal-info-msg {
            border-radius: 4px;
            border-left: 4px solid var(--sdds-blue-400);
            background: var(--sdds-grey-50);
            padding: var(--sdds-spacing-layout-16);
            display: flex;
            flex-grow: 1;
            .info-msg {
                margin-left: var(--sdds-spacing-layout-16);
                margin-right: var(--sdds-spacing-layout-8);
            }

            .assert-param-lbl {
                text-transform: uppercase;
            }
        }
    }
}