.fault-guide-container {
    display: flex;
    
    

    input::placeholder {
        font-family: inherit !important;
        font-size: 14px;
    }

    .createBtn {
        margin: 5rem 10rem;
        width: 25rem;
    }

    .table-title {
        background-color: var(--sdds-grey-400);
        color: var(--sdds-grey-958);
        height: 6vh;
        min-height: 15rem;
        width: 145rem;
        margin: 5rem 0 0 5rem;
        display: flex;
        padding-left: 3rem;
        align-items: center;

        .title {
            flex: 5;
        }

        button {
            flex: 1.5;
            margin: 2rem;
        }

        .search-icon{
            padding-right:2rem;
            cursor: pointer;
            flex: 3;
            display: flex;
            justify-content: flex-end;
        }
        .search-bar {
            flex: 2;
        }
        .close-search{
            cursor: pointer;
            height:10rem;
            border-bottom: 2px solid var(--sdds-grey-400);
            width:8rem;
            display:flex;
            align-items: center;
            background-color: var(--sdds-grey-50);
            border-radius: 0 4px 0 0;
            
         }
    }

    .table-headers {
        background-color: var(--sdds-grey-300);
        color: var(--sdds-grey-958);
        height: 4vh;
        min-height: 10rem;
        width: 145rem;
        margin: 0 0 0 5rem;
        display: flex;
        padding-left: 3rem;
        align-items: center;

        .fault-code-column {
            width: 30rem;
        }

        .fault-description-column {
            width: 110rem;
            display: flex;
            justify-content: flex-start;
        }
    }

    .fc-table-container {
        width: 145rem;
        margin: 0 5rem;
        height: 66vh;
       
        border: 1px solid var(--sdds-grey-200);
        overflow-y: scroll;
        overflow-x: hidden;

        .spinner{
            margin-top:50%;
        }
    
    }   

  
}