.sdds-inline-tabs {
  .sdds-inline-tabs--tab {
    display: flex;
    justify-content: space-between;

    span {
      line-height: 20px;
    }

    .tab-badges {
      .sdds-badges {
        display: block;
        height: 20px;
        line-height: 20px;
        width: 20px;
        border-radius: 12px;
        background-color: var(--sdds-blue-400);
        text-align: center;

        .sdds-badges-text {
          font-family: var(--sdds-font-family-semi-condensed-bold);
          font-size: 12px;
          color: var(--sdds-white);
          font-weight: bold;
        }
      }
    }

    &:focus {
      outline: 0;
    }

    &.sdds-inline-tabs--tab__active {
      background-color: var(--sdds-white);
    }
  }

  .sdds-inline-tabs-main {
    height: calc(100vh - 234px);
    overflow-y: auto;
    background-color: #fff !important;
    border: var(--sdds-grey-50);
    padding: var(--sdds-spacing-layout-24);

    &.sdds-inline-tabs-onwhite {
      background-color: #fff !important;
    }

    .tab-panel {
      .function-container:nth-child(0) {
        margin-top: var(--sdds-spacing-layout-24);
      }
    }
  }
}
