.package-management-container {

    .pkg-left-panel {
        .top-bar {
            width: 100%;
            height: 72px;
            background-color: var(--sdds-grey-400);
            display: flex;
            align-items: center;
            color: var(--sdds-grey-958);

            .registerButton {
                text-align: right;
                margin: 3rem;
            }
        }

        .search-bar {
            width: 100%;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--sdds-grey-958);
            background-color: var(--sdds-grey-200);
        }

        .add-package-btn {
            margin-left: auto;
        }

        .search-component {
            display: flex;

            .search-icon {
                border: 1px solid var(--sdds-grey-200);
                padding: 5px;

                &:focus,
                &:hover {
                    border: 1px solid var(--sdds-grey-800);
                    border-radius: 5px;
                }
            }

            .searchBar {
                flex: 2 1;

                #searchInput {
                    width: 100%
                }
            }

            .close-search {
                cursor: pointer;
                height: 10rem;
                border-bottom: 2px solid var(--sdds-grey-400);
                width: 8rem;
                display: flex;
                align-items: center;
                background-color: var(--sdds-grey-50);
                border-radius: 0 4px 0 0;
            }
        }

        .pkg-listing {
            width: 100%;
            box-sizing: border-box;
            height: calc(100vh - 360px);
            border: 1px solid var(--sdds-grey-200);
            overflow-y: auto;

            .pkg-row {
                width: 100%;
                height: 50px;
                align-items: center;
                display: flex;
                border-bottom: 1px solid var(--sdds-grey-200);
                cursor: pointer;

                &.selected {
                    background-color: var(--sdds-grey-100);
                }

                &:hover {
                    background-color: var(--sdds-grey-50);
                }

                .pkg-name {
                    color: var(--sdds-blue-500);
                    cursor: pointer;
                }

                .pkg-type-icon {
                    margin-left: auto;

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }

    .tab-panel {
        background-color: #fff;
        width: 100%;
    }
}