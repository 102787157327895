
.searchBar{
    display: flex;
    margin: 0;
    input{
     height:10rem;
     width:35rem; 
     border-radius: 4px 0 0 0 !important;  
    }    
 }
 