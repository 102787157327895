.fault-code-cell{
    height:auto;
    width:140rem;
    border-bottom: 1px solid var(--sdds-grey-200);
    display: flex;
    align-items: center;
    padding:3rem;
    cursor: pointer;
    color: var(--sdds-blue-500);

    .fault-code-column{
        width:30rem;
    }

    .fault-description-column{
        width:100rem;
        display: flex;
        justify-content: flex-start;
        line-height: 5rem;
    }

    &:hover{
        background-color: var(--sdds-grey-100);
    }
    
}
