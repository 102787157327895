:global(.test_status_spinner .spinner) {
   justify-items: left;
   justify-content: left;
}
:global(.Failed) {
    color: #FF2340 !important; 
    border-color: #FF2340 !important;    
}
:global(.Warning) {
    color: #F1C21B !important; 
    border-color: #F1C21B !important;   
}
:global(.Passed) {
    color: #1DAB8B !important;
    border-color: #1DAB8B !important;    
}
:global(.Running,
.Default) {
    color: #B0B7C4 !important;
}

.outerContainer {
    margin-bottom: var(--sdds-spacing-element-12);
    border-radius: 1rem; 
    width:100%;
    height: auto;      
    border: 1px solid #B0B7C4;
    display: flex;      
}

.textContainer {
    padding: 0 var(--sdds-spacing-element-16);
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}
.topRow{
    display: flex;
    flex-direction: row;
    
}
.col1{
    flex:1
}

.testStatusSection {
    flex: 0.6;
    padding-top: 3rem;
}

.status {
    margin-top: 1rem;
    font-weight: bold;
    color:#56657A;
}

.statusIcon {
    margin-bottom: -1rem;
    margin-right: var(--sdds-spacing-element-8);
    cursor: pointer;
    z-index:5;
}

.testNameSection {
    display: flex;
    flex-direction: column;
    margin-top:var(--sdds-spacing-element-8);
    
}
.whenLoading{
    display: flex;
}
.testName {
    font-weight:bolder;
    display: inline-block;   
    width:auto;
    color:var(--sdds-grey-800);
    margin-bottom: var(--sdds-spacing-element-8);
    
}

.pkgTestName{
    margin-right:var(--sdds-spacing-element-8);
    font-weight:bolder;
    color:var(--sdds-grey-800);   
}

.testDescription{
    margin-bottom:var(--sdds-spacing-element-8);
    color: var(--sdds-grey-700);
}

.warningBox{
    display: flex;
    background-color: var(--sdds-grey-50);
    padding: var(--sdds-spacing-element-8);
    margin-bottom:var(--sdds-spacing-element-16);
}
.warningIconContainer{
    flex:1;
}
.warningText{
    flex:16;
}

.warningBox svg{
    height: var(--sdds-spacing-element-32);
    width: var(--sdds-spacing-element-32);
    color: var(--sdds-warning);
    margin: 0 var(--sdds-spacing-element-8);
}

.logSection{
    margin-top:8px;
    height:auto;
    display: flex;
    margin-bottom:8px;
}

.logContainer{    
    background-color:var(--sdds-grey-50) ; 
    width:100%;    
}

.logsBox {
    padding: var(--sdds-spacing-element-8);  
    color:var(--sdds-grey-868) ;
}

.logRow{
    padding:1rem;
}

.viewLogBtn {    
    margin-left:2rem;
    font-size: large; 
}

.grey {
    background-color: var(--sdds-grey-100);
}

.faultCodeSection{
    display: flex; 
}

.faultDetailsSection{
    color: var(--sdds-grey-868);
    display: flex;   
    height:auto;
}

.faultcode {   
    color:var(--sdds-grey-700); 
}

.viewDetailsBtn {
    margin: var(--sdds-spacing-element-8);
    
}

.faultCodeBox {
    padding: var(--sdds-spacing-element-16);   
    border-radius: var(--sdds-spacing-element-4);   
    margin-bottom: var(--sdds-spacing-element-16);
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}
.box{
    display: block;
}
.unknownFaultCodeBox {
    box-sizing: border-box;
    width: 25%;
    height: 50px;
    display: flex;
    text-align: left;
    background: #FFFFFF;
    border: 1px solid;
    border-color: var(--sdds-warning);
    color: var(--sdds-grey-868);
    align-items: center;
    justify-content: center;  
}

.actionBtnContainer {
    display: flex;
    justify-content: flex-end;
}

.actionBtn {
    margin:var(--sdds-spacing-element-8) 0;    
    min-width:25rem;
}