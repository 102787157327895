::-webkit-scrollbar {
  width: 20px;
  background-color: white;
  margin-right: 2px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 7.5px solid white;
  background-color: #CDD1DB;
  height: 50px;
}

.dont-break-word {
  word-break: keep-all;
  word-wrap: none;
  white-space: nowrap
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;

  >.spinner {
    margin-top: 12%;
  }

  .documentation-bar {
    height: 40px;
    width: 100%;
    background-color: var(--sdds-green-100) !important;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

}

body {
  overflow: hidden;
}
.spinner-container {
  >.spinner {
    left:calc(50% - 38px);
    top:calc(50% - 38px);
    z-index:101;
    position:absolute
  }
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index:100;
  background-color: var(--sdds-grey-400);
  opacity:0.8
}

.custom-tooltip {
  display: none;
  word-wrap: break-word;
  white-space: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--sdds-white);
  background-color: var(--sdds-grey-800);
  border-radius: 1rem;
  padding: 2rem;
  max-width: 48rem;
  z-index: 20000;
  opacity: 0;
  transition: opacity 200ms ease-in 0s, visibility 200ms ease-in 0s;
}
