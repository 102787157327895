.st-tabs-wrapper {
    margin: 0 var(--sdds-spacing-layout-16);
    border: 1px solid #DBDFE680;
    min-height: 100rem;

    .header {
        background-color: #F9FAFB;
        color: var(--sdds-grey-846);
        padding: 4rem 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.step2 {
            padding: 2rem 4rem;
        }

        .step-title {
            font-weight: bold;
            text-transform: uppercase;
        }

        .info-wrapper {
            display: flex;
            padding: 0;

            .info {
                display: flex;
                align-items: center;
                margin: 0 2rem;

                span {
                    vertical-align: sub;
                }

                .warning {
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    background: var(--sdds-warning);
                    color: white;
                    margin: 0 2rem;
                }

                .fail {
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    background: #FF2340;
                    color: white;
                    margin: 0 2rem;
                }
                .warning,.fail{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

    }

    .actions {
        display: flex;
        vertical-align: middle;

        svg {
            vertical-align: sub;
        }

        .export {
            margin-right: 3rem;

            button {
                padding-left: 24px;
                color: var(--sdds-white);
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .runtests {
            margin-right: 3rem;

            button {
                padding-left: 24px;
                
                svg{
                    width: 16px;
                    height: 16px;
                }
               .sdds-btn-icon{
                color: inherit;
               }
            }
        }
    }

    .content {
        padding: 0 var(--sdds-spacing-element-8);
        display: flex;
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;

    .sdds-btn {
        padding: 2rem;
        margin: 3rem;
    }
}