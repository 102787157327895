.st-first-step {
    
    color:var(--sdds-grey-846);
    width:100%;
    .title {
        background-color: var(--sdds-grey-200);
        text-transform: uppercase;
        font-weight: bold;
        padding: var(--sdds-spacing-element-8);
        display: inline-block;
        margin: var(--sdds-spacing-element-16) 0 ;
        border-radius: var(--sdds-spacing-element-4);
    }

    
    .no-input-msg{
       display: flex;
       justify-content: center;
       color:var(--sdds-grey-800);
       margin-top:140px;
    }
    .otherWrapper {
        display: grid;
        grid-template-columns: auto auto;
    }

    .equipment {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        gap: 20px;
        .sdds-headline-06{
            padding:2px;
        }
    }

    .secondColumn {
        margin-left: 10vw;
    }

    .personnelList {
        position: relative;
        font-family: 'Scania Sans';
        margin-bottom: 10rem;
        width: 25vw;
    }

    .personnelList {
        input {
                width: 100%;
                padding: 12px;
                
                border-radius: var(--sdds-spacing-element-4);
                font-size: 3.5rem;
                text-overflow: ellipsis;
                border: 1px solid var(--sdds-grey-400);
        
                &.error {
                    border-color: var(--sdds-textfield-bar-error) !important;
                }
            }
        .sdds-textfield-helper{
            svg{
                width: 16px;
                height:16px;
            }
            .span{
                vertical-align: top;
            }
        }
    }

    .suggest {
        background-color: white;
        position: absolute;
        width: 100%;
        border: 0.789474px solid #CDD1DB;
        border-top: none;
        padding: 3rem;
        padding-top: 0;
        max-height: 230px;
        overflow-y: scroll;
    }

    .suggest>.details {
        margin: 5rem 1rem;
        cursor: pointer;
    }

    .suggest>.details>.name {
        font-weight: 700;
        font-size: 15px;
        margin-bottom: 2rem;
    }

    .suggest>.details>.customer>span {
        font-weight: 700;
        font-size: 14px;
        color: #56657A;
    }

    .suggest>.details>.customer {
        font-weight: 200;
        font-size: 14px;
        color: #56657A;
    }
}