.fault-guide-form {
    height: 76vh;
  
    overflow-y: scroll;
    flex: 1;
    background-color: var(--sdds-grey-50);
    display: flex;
    margin: 5rem 0 5rem 0;
    padding: 5rem;
    flex-direction: column;

    .form-top-bar {
        display: flex;

        .secondary-action-buttons {
            flex: 1;
            display: flex;
            justify-content: flex-end;

            .actionButton{
                margin-left:16px;
            }
        }
    }

    .form-fields {
        flex: 1;
        display: flex;
        flex-direction: column;

        .form-field {
            display: flex;
            flex-direction: column;
            margin-top: 3rem;

            input {
                border: 0px !important;    
            }

            .sdds-checkbox-item {
                margin-top: 3rem;
                margin-bottom: 5rem;
            }

            .form-label {
                min-width: 90px;
                color: var(--sdds-grey-700);
            }

            .form-label,
            .seperator {
                margin-top: 3rem;
            }

            .form-value {
                margin-top: 1rem;

                .translatable {
                    display: flex;
                }

                .translation-pending-icon {  
                    margin:2rem;
                    color:var(--sdds-blue-500);
                    svg{
                        width:20px;
                        height: 20px;
                        &:hover {
                            +.custom-tooltip {
                                display: block;
                                position: absolute;
                                opacity: 1;
                            }
                        }                        
                    }
                }

                .view-state {
                    display: flex;
                    margin-top: 3rem;
                    margin-bottom: 3rem;
                }
                .sdds-form-textfield-error {
                    .sdds-textfield-helper{
                        justify-content: unset;
                    }
                }
            }



        }
    }

    .action-button {
        margin-right: 3rem;
    }

}